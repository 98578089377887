import React from 'react';
import clsx from 'clsx';
import css from './social-icon.module.sass';

export const TwitterIcon: React.FC<SvgProps> = ({ size, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={size}
    height={size}
    className={clsx(className, css.twitter)}>
    <path
      className={css.twitterColor}
      d="M502.001,103.455c-18.027,7.995-37.55,13.529-57.959,15.951
	c20.87-12.492,36.781-32.285,44.431-55.884c-19.526,11.569-41.126,19.986-64.264,24.483c-18.295-19.525-44.584-31.824-73.563-31.824
	c-55.692,0-100.891,45.199-100.891,100.891c0,7.956,0.922,15.605,2.575,22.985c-83.942-4.228-158.235-44.316-208.085-105.426
	c-8.724,14.874-13.683,32.285-13.683,50.771c0,34.977,17.872,65.915,44.891,83.903c-16.525-0.422-31.978-5.073-45.621-12.607v1.23
	c0,48.928,34.822,89.744,80.904,99.084c-8.417,2.23-17.41,3.42-26.557,3.42c-6.457,0-12.76-0.576-19.064-1.768
	c12.914,40.087,50.118,69.182,94.28,70.105c-34.553,27.018-78.099,43.084-125.374,43.084c-8.11,0-16.064-0.46-24.02-1.344
	c44.737,28.671,97.739,45.314,154.775,45.314c185.563,0,287.068-153.738,287.068-287.028l-0.307-13.068
	C471.368,141.466,488.473,123.749,502.001,103.455L502.001,103.455z"
    />
    <path
      d="M164.776,465.823c-56.916,0-112.302-16.216-160.171-46.895c-3.912-2.507-5.582-7.378-4.03-11.758
	c1.551-4.379,5.918-7.113,10.532-6.6c7.763,0.863,15.258,1.283,22.915,1.283c35.28,0,68.932-9.222,98.619-26.864
	c-35.884-9.631-65.309-36.835-77.042-73.258c-1.082-3.358-0.314-7.037,2.02-9.683c2.335-2.645,5.891-3.863,9.356-3.21
	c0.06,0.012,0.12,0.023,0.18,0.034c-28.857-20.339-47.32-53.948-47.32-90.945v-1.23c0-3.539,1.87-6.813,4.917-8.612
	c3.048-1.798,6.819-1.852,9.917-0.142c2.635,1.455,5.305,2.772,8.003,3.951C28.496,172.996,20.564,149.78,20.564,125.4
	c0-19.73,5.207-39.036,15.058-55.831c1.657-2.825,4.592-4.659,7.857-4.911c3.262-0.247,6.447,1.112,8.517,3.65
	c17.416,21.35,37.816,39.942,60.635,55.259c4.585,3.078,5.808,9.291,2.729,13.876c-3.078,4.585-9.29,5.808-13.876,2.729
	c-20.17-13.539-38.576-29.464-54.879-47.465c-3.976,10.333-6.042,21.423-6.042,32.692c0,30.47,15.115,58.724,40.433,75.579
	c3.708,2.469,5.336,7.093,3.993,11.341c-1.345,4.248-5.337,7.086-9.79,6.98c-11.69-0.299-23.117-2.482-34.142-6.512
	c6.096,36.688,34.365,66.863,71.666,74.423c4.554,0.923,7.872,4.861,8.009,9.506s-2.943,8.771-7.435,9.961
	c-9.402,2.491-19.199,3.754-29.119,3.754c-1.105,0-2.204-0.015-3.295-0.045c15.299,28.801,45.247,47.685,78.721,48.384
	c4.228,0.088,7.943,2.827,9.279,6.839c1.336,4.013,0.003,8.431-3.328,11.036c-33.449,26.156-72.895,41.406-114.929,44.583
	c35.707,16.17,74.528,24.594,114.149,24.594c173.378,0,277.005-140.774,277.068-276.911l-0.304-12.951
	c-0.078-3.3,1.479-6.426,4.159-8.354c9.729-6.996,18.721-14.813,26.917-23.389c-9.011,2.311-18.16,4.021-27.396,5.117
	c-4.686,0.556-9.12-2.238-10.643-6.702c-1.523-4.464,0.281-9.386,4.329-11.809c10.747-6.433,19.971-14.962,27.193-24.987
	c-12.884,5.304-26.252,9.314-39.982,11.982c-3.378,0.658-6.854-0.469-9.205-2.979c-17.068-18.215-41.221-28.662-66.266-28.662
	c-50.117,0-90.891,40.773-90.891,90.891c0,6.888,0.785,13.887,2.333,20.8c0.683,3.049-0.099,6.241-2.113,8.63
	c-2.014,2.389-5.018,3.7-8.149,3.542c-21.363-1.076-42.585-4.445-63.077-10.012c-5.33-1.448-8.477-6.942-7.029-12.272
	s6.943-8.477,12.272-7.029c15.132,4.111,30.695,6.937,46.416,8.434c-0.434-4.04-0.653-8.08-0.653-12.093
	c0-61.146,49.745-110.891,110.891-110.891c28.64,0,56.332,11.207,76.848,30.923c19.641-4.386,38.415-11.837,55.881-22.186
	c3.577-2.119,8.096-1.804,11.344,0.794s4.549,6.936,3.266,10.893c-4.128,12.736-10.459,24.509-18.604,34.83
	c6.284-2.046,12.479-4.422,18.566-7.121c4.067-1.804,8.835-0.704,11.702,2.698c2.866,3.402,3.141,8.289,0.673,11.99
	c-13.264,19.897-29.619,37.264-48.666,51.684l0.185,7.874c0.002,0.078,0.003,0.157,0.003,0.235
	c0,72.064-27.864,145.002-76.447,200.111c-26.378,29.921-57.762,53.435-93.28,69.887
	C253.397,456.729,210.553,465.823,164.776,465.823z M147.323,164.429c-1.394,0-2.809-0.293-4.159-0.911l-0.463-0.213
	c-5.015-2.314-7.204-8.256-4.889-13.27c2.314-5.015,8.255-7.205,13.27-4.889l0.414,0.189c5.021,2.301,7.226,8.236,4.926,13.257
	C154.739,162.263,151.113,164.429,147.323,164.429z"
    />
    <path
      d="M371.522,307.61c-1.749,0-3.518-0.458-5.125-1.423c-4.736-2.841-6.28-8.969-3.438-13.705l0.018-0.029
	c2.841-4.736,8.985-6.271,13.72-3.43c4.736,2.841,6.271,8.984,3.43,13.72C378.249,305.871,374.926,307.61,371.522,307.61z"
    />
    <path
      d="M286.565,391.241c-3.472,0-6.845-1.81-8.691-5.04c-2.74-4.795-1.075-10.903,3.72-13.644
	c23.311-13.323,44.007-30.847,61.512-52.085c3.513-4.262,9.816-4.868,14.077-1.356c4.262,3.513,4.869,9.815,1.356,14.077
	c-19.052,23.114-41.601,42.2-67.021,56.729C289.953,390.816,288.247,391.241,286.565,391.241z"
    />
  </svg>
);
