import React from 'react';
import clsx from 'clsx';
import css from './social-icon.module.sass';

export const LinkedInIcon: React.FC<SvgProps> = ({ size, className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={size} height={size} className={clsx(className, css.linkedin)}>
    <path
      className={css.linkedinBg}
      d="M446,502H66c-30.928,0-56-25.072-56-56V66c0-30.928,25.072-56,56-56h380c30.928,0,56,25.072,56,56
	v380C502,476.928,476.928,502,446,502z"
    />
    <g>
      <path
        className={css.linkedinLetter}
        d="M404.906,216.758c-12.231-14.038-29.169-21.057-50.81-21.057c-16.01,0-29.583,3.179-40.725,9.534
		c-11.142,6.358-22.157,16.939-33.041,31.743V200.73h-72.805v222.518h72.268V297.061c0-22.101,16.499-40.334,37.543-41.489
		l2.55-0.14c19.156-1.051,35.237,14.955,35.237,35.073v132.742h68.128V281.816C423.25,252.482,417.134,230.797,404.906,216.758z"
      />
      <rect x="93.07" y="200.73" className={css.linkedinLetter} width="72.805" height="222.52" />
      <circle className={css.linkedinLetter} cx="129.48" cy="129.48" r="40.727" />
    </g>
    <path
      d="M446,512H66c-36.393,0-66-29.607-66-66V66C0,29.607,29.607,0,66,0h380c36.393,0,66,29.607,66,66v380
	C512,482.393,482.393,512,446,512z M66,20c-25.364,0-46,20.636-46,46v380c0,25.364,20.636,46,46,46h380c25.364,0,46-20.636,46-46V66
	c0-25.364-20.636-46-46-46H66z"
    />
    <path
      d="M423.25,433.247h-68.128c-5.522,0-10-4.477-10-10V290.505c0-7.187-2.89-14.044-7.929-18.813
	c-4.632-4.385-10.592-6.606-16.76-6.274l-2.549,0.14c-15.753,0.864-28.092,14.702-28.092,31.503v126.187c0,5.523-4.478,10-10,10
	h-72.268c-5.522,0-10-4.477-10-10V200.729c0-5.523,4.478-10,10-10h72.805c5.522,0,10,4.477,10,10v9.537
	c5.962-5.663,12.004-10.248,18.083-13.718c12.625-7.199,27.994-10.849,45.683-10.849c24.56,0,44.191,8.239,58.35,24.488
	c0,0,0,0.001,0.001,0.001c13.804,15.848,20.804,39.947,20.804,71.626v141.431C433.25,428.77,428.772,433.247,423.25,433.247z
	 M365.122,413.247h48.128V281.816c0-26.71-5.345-46.389-15.886-58.491l0.001,0.001c-10.334-11.861-24.487-17.627-43.27-17.627
	c-14.171,0-26.206,2.766-35.771,8.221c-9.868,5.631-19.941,15.382-29.937,28.979c-2.561,3.483-7.065,4.926-11.175,3.579
	c-4.106-1.347-6.883-5.18-6.883-9.502v-26.248h-52.805v202.518h52.268V297.061c0-27.417,20.643-50.027,46.995-51.473l2.549-0.14
	c11.713-0.648,22.942,3.519,31.605,11.719c9.012,8.531,14.18,20.682,14.18,33.338L365.122,413.247L365.122,413.247z"
    />
    <path
      d="M165.879,433.247H93.074c-5.522,0-10-4.477-10-10V369c0-5.523,4.478-10,10-10s10,4.477,10,10v44.247h52.805V210.729h-52.805
	V284c0,5.523-4.478,10-10,10s-10-4.477-10-10v-83.271c0-5.523,4.478-10,10-10h72.805c5.522,0,10,4.477,10,10v222.518
	C175.879,428.77,171.401,433.247,165.879,433.247z"
    />
    <path
      d="M93.069,339c-2.63,0-5.21-1.07-7.069-2.93c-1.86-1.86-2.931-4.44-2.931-7.07s1.07-5.21,2.931-7.07
	c1.859-1.86,4.439-2.93,7.069-2.93c2.641,0,5.211,1.07,7.08,2.93c1.86,1.86,2.931,4.44,2.931,7.07s-1.07,5.21-2.931,7.07
	C98.28,337.93,95.71,339,93.069,339z"
    />
    <path
      d="M129.477,180.207c-27.971,0-50.727-22.756-50.727-50.727s22.756-50.727,50.727-50.727c27.972,0,50.728,22.756,50.728,50.727
	S157.448,180.207,129.477,180.207z M129.477,98.753c-16.942,0-30.727,13.784-30.727,30.727s13.784,30.727,30.727,30.727
	s30.728-13.784,30.728-30.727S146.42,98.753,129.477,98.753z"
    />
    <path d="M388.636,68h-81.5c-5.522,0-10-4.477-10-10s4.478-10,10-10h81.5c5.522,0,10,4.477,10,10S394.158,68,388.636,68z" />
    <path d="M261.755,68h-0.119c-5.522,0-10-4.477-10-10s4.478-10,10-10h0.119c5.522,0,10,4.477,10,10S267.277,68,261.755,68z" />
  </svg>
);
