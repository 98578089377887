import React from 'react';
import clsx from 'clsx';
import css from './social-icon.module.sass';

export const CvIcon: React.FC<SvgProps> = ({ size, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={size}
    height={size}
    className={clsx(className, css.cv)}>
    <path
      className={css.cvBg}
      d="M394.007,502h-364c-11.046,0-20-8.954-20-20V30c0-11.046,8.954-20,20-20h364c11.046,0,20,8.954,20,20
	v452C414.007,493.046,405.053,502,394.007,502z"
    />
    <circle className={css.cvProfileBg} cx="212.01" cy="146" r="82" />
    <path
      className={css.cvProfileHead}
      d="M238.007,135.5v6.5c0,14.359-11.641,26-26,26l0,0l0,0c-14.359,0-26-11.641-26-26v-6.5
	c0-14.359,11.641-26,26-26l0,0C226.366,109.5,238.007,121.141,238.007,135.5z"
    />
    <path
      className={css.cvProfileBody}
      d="M212.007,168L212.007,168c-25.38,0-47.064,15.767-55.832,38.033
	C170.815,219.655,190.432,228,212.007,228s41.192-8.345,55.832-21.967C259.071,183.767,237.387,168,212.007,168z"
    />
    <path
      className={css.cvPenBody}
      d="M436.574,160.59l5.714-9.897c8.837-15.305,28.407-20.549,43.713-11.713l0,0
	c15.305,8.837,20.549,28.407,11.713,43.713L371.873,400.656l-55.426-32l54.282-94.02L436.574,160.59z"
    />
    <path
      className={css.cvPenEnd}
      d="M486.002,138.98L486.002,138.98c-15.305-8.837-34.876-3.593-43.713,11.713l-14.857,25.733l55.426,32
	l14.857-25.733C506.551,167.388,501.307,147.817,486.002,138.98z"
    />
    <polygon className={css.cvPenStart} points="371.873,400.656 307.589,448 316.447,368.656 " />
    <path
      d="M414.007,398.328c-5.522,0-10,4.477-10,10V482c0,5.514-4.486,10-10,10h-364c-5.514,0-10-4.486-10-10V30
	c0-5.514,4.486-10,10-10h364c5.514,0,10,4.486,10,10v108.75c0,5.523,4.478,10,10,10s10-4.477,10-10V30c0-16.542-13.458-30-30-30
	h-364c-16.542,0-30,13.458-30,30v452c0,16.542,13.458,30,30,30h364c16.542,0,30-13.458,30-30v-73.672
	C424.007,402.805,419.529,398.328,414.007,398.328z"
    />
    <path
      d="M304.007,146c0-50.729-41.271-92-92-92s-92,41.271-92,92c0,26.317,11.11,50.085,28.882,66.869
	c0.333,0.356,0.687,0.693,1.074,1c16.371,14.979,38.158,24.13,62.043,24.13s45.672-9.152,62.043-24.13
	c0.387-0.307,0.741-0.645,1.074-1C292.897,196.085,304.007,172.317,304.007,146z M212.007,74c39.701,0,72,32.299,72,72
	c0,15.967-5.231,30.73-14.06,42.683c-7.375-10.938-17.596-19.445-29.463-24.697c4.71-6.087,7.523-13.712,7.523-21.986v-6.5
	c0-19.851-16.149-36-36-36s-36,16.149-36,36v6.5c0,8.274,2.813,15.899,7.523,21.986c-11.867,5.252-22.088,13.759-29.463,24.697
	c-8.829-11.953-14.06-26.716-14.06-42.683C140.007,106.299,172.306,74,212.007,74z M196.007,142v-6.5c0-8.822,7.178-16,16-16
	s16,7.178,16,16v6.5c0,8.822-7.178,16-16,16S196.007,150.822,196.007,142z M168.516,203.332
	c8.789-15.585,25.19-25.332,43.491-25.332s34.702,9.747,43.491,25.332C243.405,212.528,228.336,218,212.007,218
	S180.608,212.528,168.516,203.332z"
    />
    <path d="M266.007,438h-54c-5.522,0-10,4.477-10,10s4.478,10,10,10h54c5.522,0,10-4.477,10-10S271.529,438,266.007,438z" />
    <path d="M266.007,382h-142c-5.522,0-10,4.477-10,10s4.478,10,10,10h142c5.522,0,10-4.477,10-10S271.529,382,266.007,382z" />
    <path d="M266.007,326h-142c-5.522,0-10,4.477-10,10s4.478,10,10,10h142c5.522,0,10-4.477,10-10S271.529,326,266.007,326z" />
    <path
      d="M88.366,272.93c-1.859-1.86-4.439-2.93-7.079-2.93c-2.631,0-5.211,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07
	s1.069,5.21,2.93,7.07c1.87,1.86,4.439,2.93,7.07,2.93c2.64,0,5.21-1.07,7.079-2.93c1.86-1.86,2.931-4.44,2.931-7.07
	S90.227,274.79,88.366,272.93z"
    />
    <path
      d="M88.366,328.93c-1.869-1.86-4.439-2.93-7.079-2.93c-2.631,0-5.2,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07
	s1.069,5.21,2.93,7.07c1.87,1.86,4.439,2.93,7.07,2.93c2.64,0,5.21-1.07,7.079-2.93c1.86-1.86,2.931-4.44,2.931-7.07
	S90.227,330.79,88.366,328.93z"
    />
    <path
      d="M81.287,382c-2.631,0-5.2,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07s1.069,5.21,2.93,7.07
	c1.859,1.86,4.439,2.93,7.07,2.93c2.64,0,5.22-1.07,7.079-2.93c1.86-1.86,2.931-4.44,2.931-7.07s-1.07-5.21-2.931-7.07
	C86.497,383.07,83.927,382,81.287,382z"
    />
    <path d="M266.007,270h-142c-5.522,0-10,4.477-10,10s4.478,10,10,10h142c5.522,0,10-4.477,10-10S271.529,270,266.007,270z" />
    <path
      d="M491.002,130.32c-9.715-5.609-21.033-7.099-31.871-4.196c-10.836,2.904-19.894,9.854-25.502,19.569L307.787,363.656
	c-0.689,1.195-1.125,2.52-1.278,3.891l-8.858,79.344c-0.44,3.948,1.498,7.783,4.938,9.77c1.553,0.896,3.278,1.34,4.999,1.34
	c2.092,0,4.176-0.655,5.931-1.948l64.284-47.344c1.111-0.818,2.041-1.857,2.73-3.052l125.841-217.963
	C517.954,167.638,511.058,141.9,491.002,130.32z M324.689,384.962l28.942,16.71l-33.568,24.722L324.689,384.962z M368.213,386.996
	l-38.105-22l100.985-174.91l38.105,22L368.213,386.996z M489.054,177.693l-9.857,17.073l-38.105-22l9.857-17.073
	c2.938-5.089,7.682-8.729,13.358-10.25c5.678-1.522,11.606-0.74,16.694,2.198c5.089,2.938,8.729,7.682,10.25,13.358
	C492.772,166.675,491.992,172.604,489.054,177.693z"
    />
  </svg>
);
